var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problemList"},[_c('div',{staticClass:"content",staticStyle:{"margin-top":"20px"}},[_c('el-card',{attrs:{"shadow":"never"}},[_c('el-form',{staticClass:"tab_form",attrs:{"model":_vm.form,"label-width":'110px'}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"label":"课程名称："}},[_c('el-input',{attrs:{"placeholder":"请输入课程名称"},model:{value:(_vm.form.course_name),callback:function ($$v) {_vm.$set(_vm.form, "course_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.course_name"}})],1)],1),_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"label":"章节："}},[_c('el-input',{attrs:{"placeholder":"请输入章节"},model:{value:(_vm.form.unit_name),callback:function ($$v) {_vm.$set(_vm.form, "unit_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.unit_name"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-search"},on:{"click":_vm.getTeamList}},[_vm._v("查询 ")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.clickReset}},[_vm._v("重置 ")])],1)],1)],1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box"},[_c('div',{staticClass:"content-left"},[(_vm.tableData.length > 0)?_c('div',{staticClass:"table-content"},_vm._l((_vm.tableData),function(item,index){return _c('el-collapse',{key:index,staticClass:"itembox",attrs:{"value":'1'}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('div',{staticStyle:{"width":"100%"},attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"title",staticStyle:{"padding-right":"20px"}},[_c('div',{staticClass:"left",staticStyle:{"flex":"1"}},[_vm._v(" 【"+_vm._s(item.teach_name)+"】"+_vm._s(item.course_name)+" - 学习进度 ")]),(
                      item.is_complate === true && item.evaluate_count > 0
                    )?_c('div',{staticClass:"right operate"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$handleRoute(
                          {
                            course_id: item.course_id,
                            name: item.course_name,
                            study_user_id: _vm.study_user_id,
                          },
                          _vm.priexRoute + 'Comment'
                        )}}},[_vm._v(" 结果评论 ")])],1):_vm._e()])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px","font-size":"18px","font-weight":"bold"}},[_c('div',{staticStyle:{"width":"36%"}},[_vm._v("章节/条目")]),_c('div',{staticStyle:{"width":"300px"}},[_vm._v("作业名称")]),_c('div',[_vm._v("完成情况")])]),_c('div',{staticClass:"contents"},_vm._l((item.list),function(item2,index2){return _c('div',{key:index2,staticClass:"item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item2.unit_name))]),_c('ul',_vm._l((item2.list),function(item3,index3){return _c('li',{key:index3},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v(" "+_vm._s(item3.list_name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item3.is_complate),expression:"item3.is_complate"}],staticClass:"tow"},[_c('i',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"right",staticStyle:{"display":"flex","flex-direction":"column"}},[(item3.question_other_id > 0)?_vm._l((item3.task),function(v,i){return _c('div',{key:i,staticClass:"rightitem"},[_c('div',{staticStyle:{"width":"300px"}},[_vm._v(_vm._s(v.title))]),( v.grade!=-1)?_c('div',{staticClass:"jdt"},[_c('el-progress',{attrs:{"text-inside":true,"stroke-width":20,"color":"#00957e","format":_vm.format,"percentage":v.total_grade
                                      ? (
                                          v.grade / v.total_grade
                                        ).toFixed(2) * 100
                                      : 0}})],1):_vm._e(),( v.grade==-1)?_c('div',{staticClass:"jdt"},[_vm._v(" 未做 ")]):_vm._e(),( v.grade==-2)?_c('div',{staticClass:"jdt"},[_vm._v(" 判题中 ")]):_vm._e(),_c('div',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(item3.task_grade + "分/" + item3.total_grade + "分")+" ")]),_c('div',{staticStyle:{"display":"flex","margin-left":"20px","flex":"1"}},[_c('div',[_vm._v("作业提交时间：")]),_c('div',[_vm._v(_vm._s(v.create_date || '--'))])])])}):_vm._e()],2)])])}),0)])}),0)])],1)}),1):_c('div',{staticClass:"table-content",staticStyle:{"color":"#606266","text-align":"center","line-height":"100px"}},[_vm._v(" 暂无数据 ")]),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.currentChange}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }