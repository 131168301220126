<template>
  <div class="problemList">
    <div class="content" style="margin-top: 20px">
      <!-- <Breadcrumb></Breadcrumb> -->
      <el-card shadow="never">
        <el-form :model="form" :label-width="'110px'" class="tab_form">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="课程名称：">
                <el-input
                  v-model.trim="form.course_name"
                  placeholder="请输入课程名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="章节：">
                <el-input
                  v-model.trim="form.unit_name"
                  placeholder="请输入章节"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="getTeamList"
                >查询
              </el-button>
              <el-button size="small" type="primary" @click="clickReset"
                >重置
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <div class="box" v-loading="loading">
        <div class="content-left">
          <div class="table-content" v-if="tableData.length > 0">
            <el-collapse
              :value="'1'"
              class="itembox"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <el-collapse-item name="1">
                <div slot="title" style="width: 100%">
                  <div class="title" style="padding-right: 20px">
                    <div class="left" style="flex: 1">
                      【{{ item.teach_name }}】{{ item.course_name }} - 学习进度
                    </div>
                    <div
                      class="right operate"
                      v-if="
                        item.is_complate === true && item.evaluate_count > 0
                      "
                    >
                      <el-button
                        type="primary"
                        size="small"
                        @click="
                          $handleRoute(
                            {
                              course_id: item.course_id,
                              name: item.course_name,
                              study_user_id,
                            },
                            priexRoute + 'Comment'
                          )
                        "
                      >
                        结果评论
                        <!--                        <i class="el-icon-d-arrow-right"></i>-->
                      </el-button>
                    </div>
                  </div>
                </div>
                <div style="display: flex;margin-top: 20px;font-size: 18px;font-weight: bold">
                  <div style="width: 36%;">章节/条目</div>
                  <div style="width: 300px;">作业名称</div>
                  <div>完成情况</div>
                </div>
                <div class="contents">
                  <div
                    class="item"
                    v-for="(item2, index2) in item.list"
                    :key="index2"
                  >
                    <div class="name">{{ item2.unit_name }}</div>
                    <ul>
                      <li v-for="(item3, index3) in item2.list" :key="index3">
                        <div class="section">
                          <div class="left">
                            <!-- <span> 第 {{unbd(index3)}} 节</span> -->
                            <span> {{ item3.list_name }}</span>
                            <span class="tow" v-show="item3.is_complate"
                              ><i class="el-icon-check"></i>
                            </span>
                          </div>
                          <div class="right" style="display: flex;flex-direction: column;">
                            <template v-if="item3.question_other_id > 0">
                              <div class="rightitem" v-for="v,i in item3.task" :key="i">
                                <div style="width: 300px;">{{v.title}}</div>
                                <div class="jdt" v-if=" v.grade!=-1">
                                  <el-progress
                                    :text-inside="true"
                                    :stroke-width="20"
                                    color="#00957e"
                                    :format="format"
                                    :percentage="
                                      v.total_grade
                                        ? (
                                            v.grade / v.total_grade
                                          ).toFixed(2) * 100
                                        : 0
                                    "
                                  ></el-progress>
                                </div>
                                <div class="jdt" v-if=" v.grade==-1">
                                  未做
                                </div>
                                <div class="jdt" v-if=" v.grade==-2">
                                  判题中
                                </div>
                                <div style="margin-left: 15px">
                                  {{
                                    item3.task_grade +
                                    "分/" +
                                    item3.total_grade +
                                    "分"
                                  }}
                                </div>
                                <div style="display: flex;margin-left: 20px;flex: 1;">
                                  <div>作业提交时间：</div>
                                  <div>{{ v.create_date || '--' }}</div>
                                </div>
                              </div>
                            </template>
                          </div>

                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div
            class="table-content"
            style="color: #606266; text-align: center; line-height: 100px"
            v-else
          >
            暂无数据
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="currentChange"
              :page-size="pageSize"
              :current-page="page"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { speed } from "@/api/ladder.js";

export default {
  components: {},
  props: ["study_user_id", "course_id"],
  data() {
    return {
      form: {
        course_name: "",
        unit_name: "",
      },
      page: 1,
      pageSize: 999,
      total: 0,
      loading: false,
      tableData: [],
      num: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      priexRoute: "",
      courseId: 0,
    };
  },
  created() {
    this.courseId = this.$router.currentRoute.query.course_id;
    this.getTeamList();
    console.log(this.courseId, "this.course_idthis.course_id");
  },
  computed: {
    unbd() {
      return (v) => {
        if (v < 9) {
          return this.num[v];
        } else if (v == 9) {
          return this.num[9];
        } else if (v > 9) {
          let i = (v + 1) / 10 + "";
          let j = i.split(".");
          // console.log(i.split('.'),i);

          return (
            (v + 1 < 20 ? this.num[9] : this.num[j[0] - 1]) + this.num[j[1] - 1]
          );
          // console.log(v,'===');
        }
      };
    },
  },
  methods: {
    routeUpdate() {
      this.priexRoute =
        this.$route.name.substr(0, this.$route.name.indexOf("tudySpeed")) +
        "tudy";
      // this.getTeamList();
    },
    clickReset() {
      this.form = {
        course_name: "",
        unit_name: "",
      };
      this.page = 1;
      this.pageSize = 10;
      this.getTeamList();
    },
    // 点击结果评论
    clickComment(v) {
      this.$router.push({
        path: "/me/comment",
        query: { course_id: v.course_id, tid: v.teach_id, name: v.course_name },
      });
    },
    format(percentage) {
      return percentage === 100 ? "" : `${percentage}分`;
    },
    // 点击官咖
    clickLadder(v) {
      if (v.state == 1) {
        return this.$message.info("还未解锁");
      } else {
        this.$router.push({
          path: "/ladder/stage",
          query: { id: v.id, name: v.name },
        });
      }
    },
    // 课程列表
    getTeamList() {
      this.loading = true;
      speed({
        page: this.page,
        pageSize: 999,
        course_id: this.courseId,
        study_user_id: this.study_user_id,
        ...this.form,
      })
        .then(({ data }) => {
          console.log(data, "---");
          this.loading = false;
          this.tableData = data.data;
          this.total = data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 分页
    currentChange(e) {
      this.page = e;
      this.getTeamList();
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-collapse,
  .el-collapse-item__header {
    border: none;
  }
  .el-progress-bar__innerText {
    display: inline-block;
    color: #fff !important;
  }
  li {
    list-style: none;
  }
  .itembox:first-of-type {
    margin-top: 2rem;
  }
  .itembox {
    margin-bottom: 3rem;
  }
  .el-form-item {
    margin: 0;
  }
  .pagination {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }
  .box {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 100%;

      .table-content {
        padding: 1rem;
        background-color: #fff;
        box-sizing: border-box;
        .title {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          padding-bottom: 1rem;
          .left,
          .right {
            font-size: 22px;
            color: #000;
            font-weight: 500;
            .el-icon-d-arrow-right {
              font-weight: 700;
              font-size: 20px;
            }
          }
          .right {
            cursor: pointer;
          }
        }
        .contents {
          .item {
            padding-top: 0.8rem;
            .name {
              margin: 1rem 0;
            }

            ul {
              li {
                margin: 0.5rem 0 0 1.5rem;
                .section {
                  display: flex;
                  justify-content: space-between;
                  .left,
                  .right {
                    display: flex;
                  }
                  .left {
                    width: 35%;

                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    span:nth-child(1) {
                      display: inline-block;
                    }
                    span:nth-child(2) {
                      margin: 0 1rem;
                    }
                    .tow {
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                      text-align: center;
                      line-height: 20px;
                      border-radius: 100%;
                      background: #00957e;
                      .el-icon-check {
                        font-weight: 800;
                        font-size: 14px;
                        color: #fff;
                      }
                    }
                  }
                  .right {
                    flex: 1;
                    .rightitem{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .jdt {
                      width: 20%;
                    }
                    }
                   
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
